(function() {

    var Forum = {};

    Forum.init = function () {
        this._bind = function (fn, me) {
            return function () {
                return fn.apply(me, arguments);
            };
        };

        this.handleTextareas();
    };

    Forum.handleTextareas = function() {
        var textAreas = $('textarea');

        if (textAreas.length) {

        }
    };

    $(function() {
        return Forum.init();
    });

}).apply(this);